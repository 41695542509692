<template>
  <b-row>
    <b-card-body v-if="itemsData.length != 0">
      <b-card-text>
        {{ description }}
      </b-card-text>
      <bulk-table :perPage="perPage" :currentPage="currentPage" :itemsData="itemsData" :fieldsData="fieldsData" />
      <div v-if="canVerify">
        <div @click="toggleVerified">
          <h5 class="mb-1">
            {{ $t('bulk.table.ok_title') }}
          </h5>
          <b-form-checkbox v-model="localVerified" plain>
            {{ $t('bulk.table.ok_confirm_check') }}
          </b-form-checkbox>
          <p class="text-muted">
            {{ $t('bulk.table.ok_confirm_message') }}
          </p>
        </div>
        <div style="text-align:center;">
          <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBack">
            {{ $t('bulk.back') }}
          </b-button>
          <b-button variant="primary" class="mt-1" type="button"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext"
            :disabled="!localVerified">
            {{ $t('bulk.continue') }}
          </b-button>
        </div>
      </div>
      <div v-else style="text-align:center;">
        <b-button variant="outline-danger">
          {{ $t('bulk.table.validation_error') }}
        </b-button>
        <div>
          <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBackAndReset">
            {{ $t('bulk.back') }}
          </b-button>
        </div>
      </div>
    </b-card-body>
    <b-card-body v-else>
      <h4 style="text-align: center; margin: 20px;">
        <feather-icon icon="AlertTriangleIcon" class="alertUpload" />
        <br>
        <br>
        {{ emptyMessage }}
      </h4>
      <div style="text-align:center;">
        <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goBack">
          {{ $t('bulk.back') }}
        </b-button>
        <b-button variant="primary" class="mt-1" type="button"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="goNext"
          :disabled="!localVerified">
          {{ $t('bulk.continue') }}
        </b-button>
      </div>
    </b-card-body>
  </b-row>
</template>

<script>
import {
  BButton,
  BCardText,
  BCardBody,
  BCardTitle,
  BFormCheckbox,
  BRow,
} from 'bootstrap-vue'
import BulkTable from './BulkTable.vue'

export default {
  components: {
    BButton,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    BulkTable,
    BRow,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    itemsData: {
      type: Array,
      required: true
    },
    fieldsData: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    canVerify: {
      type: Boolean,
      required: true
    },
    emptyMessage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localVerified: this.verified
    }
  },
  methods: {
    goBack() {
      this.$emit('goBack', this.index);
    },
    goNext() {
      this.$emit('goNext', this.index);
    },
    goBackAndReset() {
      this.$emit('goBackAndReset', this.index);
    },
    toggleVerified() {
      this.localVerified = !this.localVerified;
    }
  },
  watch: {
    verified(newVal) {
      this.localVerified = newVal;
    },
    localVerified(newVal) {
      this.$emit('update:verified', newVal);
    }
  }
}
</script>