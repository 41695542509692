<template>
  <div>
    <b-card class="card-transaction" no-body> <!-- RESUMEN -->
      <b-card-header>
        <b-card-title>{{ title }}</b-card-title>
      </b-card-header>
      <b-card-body> <!-- CREATED -->
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-success">
              <feather-icon size="18" icon="UserPlusIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-row>
              <b-col>
                <h6 class="transaction-title">{{$t('bulk.tab_summary.created.title')}}</h6>
                <small>{{$t('bulk.tab_summary.created.teacher_description')}}</small>
              </b-col>
              <b-col>
                <div class="font-weight-bolder" style="font-size: 25px; text-align: center;">
                  {{ bulkData[0] }}
                </div>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-card-body>
      <b-card-body> <!-- MODIFIED -->
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <feather-icon size="18" icon="Edit2Icon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-row>
              <b-col>
                <h6 class="transaction-title">{{$t('bulk.tab_summary.modified.title')}}</h6>
                <small>{{$t('bulk.tab_summary.modified.teacher_description')}}</small>
              </b-col>
              <b-col>
                <div class="font-weight-bolder" style="font-size: 25px; text-align: center;">
                  {{ bulkData[1] }}
                </div>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-card-body>
      <b-card-body v-if="bulkData[2]"> <!-- OVERWRITE -->
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <feather-icon size="18" icon="HomeIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-row>
              <b-col>
                <h6 class="transaction-title">{{$t('bulk.tab_summary.transladated.title')}}</h6>
                <small>{{$t('bulk.tab_summary.transladated.teacher_description')}}</small>
              </b-col>
              <b-col>
                <div class="font-weight-bolder" style="font-size: 25px; text-align: center;">
                  {{ bulkData[2] }}
                </div>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-card-body>
      <b-card-body> <!-- GROUPS -->
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <feather-icon size="18" icon="UsersIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-row>
              <b-col>
                <h6 class="transaction-title">{{$t('bulk.tab_summary.group.title')}}</h6>
                <small>{{$t('bulk.tab_summary.group.teacher_description')}}</small>
              </b-col>
              <b-col>
                <div class="font-weight-bolder" style="font-size: 25px; text-align: center;">
                  {{ bulkData[3] }}
                </div>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-card-body>
      <b-card-body> <!-- UNCHANGED -->
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-dark">
              <feather-icon size="18" icon="UserIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-row>
              <b-col>
                <h6 class="transaction-title">{{$t('bulk.tab_summary.unchanged.title')}}</h6>
                <small>{{$t('bulk.tab_summary.unchanged.teacher_description')}}</small>
              </b-col>
              <b-col>
                <div class="font-weight-bolder" style="font-size: 25px; text-align: center;">
                  {{ bulkData[4] }}
                </div>
              </b-col>
            </b-row>
          </b-media-body>
        </b-media>
      </b-card-body>
    </b-card>
    <hr/>
    <b-row class="mt-1 ml-5">
      <h6 class="transaction-title">{{$t('bulk.tab_summary.teacher.date_description')}}</h6>
    </b-row>
    <b-row class="mt-1 justify-content-center" v-if="datesUpdated"> <!-- SELECTOR FECHAS -->
      <b-col md="5" sm="12" >
        <b-form-group>
          <b-form-datepicker 
          :id="`startDate-${dateId}`" 
          :value="startDate"
          v-model="startDate"
          :min="adminDates[0]"
          :max="adminDates[1]"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
          today-button
          reset-button
          start-weekday="1"
          close-button
          placeholder="d-m-Y" 
          label-reset-button="Reset" />
        </b-form-group>
      </b-col>
      <b-col md="5" sm="12">
        <b-form-group>
          <b-form-datepicker 
          :id="`endDate-${dateId}`" 
          :value="endDate"
          v-model="endDate"
          :min="adminDates[0]"
          :max="adminDates[1]"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
          today-button
          reset-button
          close-button
          start-weekday="1"
          placeholder="d-m-Y" 
          label-reset-button="Reset" />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="divider">
      <div class="divider-text">
        {{ $t("auth.or") }}
      </div>
    </div>
    <b-row class="justify-content-center">
      <b-col class="text-center">
        <b-button @click="toggleAcademicYearDates" variant="outline-primary">{{ academicYearLabel }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormDatepicker,
    BFormGroup,
    BMedia,
    BMediaAside,
    BMediaBody,
    BRow,
    BFormCheckbox,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    bulkData: {
      type: Array,
      required: true
    },
    dateId: {
      type: Number,
      required: true
    },
    adminDates: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      datesUpdated: false,
      isNorthernHemisphere: true,
    }
  },
  watch: {
    startDate(){
      var event = "startDate" + this.dateId
      this.$emit(event, this.startDate);
    },
    endDate(){
      var event = "endDate" + this.dateId
      this.$emit(event, this.endDate);
    },
  },
  computed: {
    academicYearLabel() {
      if (this.startDate === null || this.endDate === null) {
        return 'Establecer Curso Académico'
      }
      const currentYear = new Date().getFullYear()
      const nextYear = currentYear + 1
      return this.isNorthernHemisphere
        ? `Curso Académico ${nextYear} (Hemisferio Sur)`
        : `Curso Académico ${currentYear}-${nextYear}`
    }
  },
  mounted() {
    this.datesUpdated = false
    if (this.adminDates[0] == null){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        this.adminDates[0] = `${year}-${month}-${day}`
      } 
      if (this.adminDates[1] == null){
        const currentDate = new Date();
        const year = currentDate.getFullYear() + 3;
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        this.adminDates[1] = `${year}-${month}-${day}`
      }
    this.datesUpdated = true
  },
  directives: {
  },
  methods: {
  toggleAcademicYearDates() {
    let currentYear = new Date().getFullYear()
    const currentMonth = new Date().getUTCMonth()
    if (this.isNorthernHemisphere) {
      if (currentMonth <= 5) currentYear = currentYear - 1
      const startYear = currentYear
      const endYear = currentYear + 1
      this.startDate = `${startYear}-09-01`
      this.endDate = `${endYear}-08-31`
    } else {
      if (currentMonth == 11) currentYear = currentYear + 1
      const startYear = currentYear
      const endYear = currentYear
      this.startDate = `${startYear}-02-01`
      this.endDate = `${endYear}-01-31`
    }
    if (new Date(this.adminDates[0]) > new Date(this.startDate)) {
      this.startDate = this.adminDates[0]
    }
    if (new Date(this.adminDates[1]) < new Date(this.endDate)) {
      this.endDate = this.adminDates[1]
    }
    this.isNorthernHemisphere = !this.isNorthernHemisphere
  }
}
}
</script>

<style>
hr {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>